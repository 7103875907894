import React, { useEffect } from 'react';
// import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import cc from 'classcat';
import styles from '../header.module.sass';
import { Anchor, Text } from '../../../../Text';
import { Icon } from '../../../../Icon';
import { IconSVG } from '../../../../SpriteSVG';
import { YaAndGaHeader } from '../../../../../lib/YaAndGaHelper';
import { useMarketingStore } from '../../../../../providers/RootStoreProvider';
import { HeaderSeason } from '../HeaderSeason';

const HeaderCruises = observer(
  ({ isSea, shipsSea, popularPopdirs, urlBF, isMobile }) => {
    const allStartCities = isSea || shipsSea ? '/popdir?type=sea' : '/popdir';
    const store = useMarketingStore();

    useEffect(() => {
      store.setIsSea(isSea);
      store.getRiverDistention();
    }, [isSea]);
    // const allCruises =
    //   isSea || shipsSea
    //     ? '/result?type=sea'
    //     : '/result';

    const popularDir =
      popularPopdirs?.popularDirections || store.popularDirections;
    const startCities =
      popularPopdirs?.departureCities || store.departureCities;

    return (
      <div
        className={cc([
          [styles.dropDownMenu],
          {
            [styles.dropDownMenuBf]: urlBF === 'black_friday',
          },
        ])}
      >
        <div className="container">
          <div className={styles.dropDownMenuWrapper}>
            <div>
              {isSea ? (
                <Text style={{ paddingLeft: 4 }} variant="heading-3">
                  Города отправления
                </Text>
              ) : (
                <div className={styles.seasonItemWrapper}>
                  <Anchor
                    href={allStartCities}
                    variant="heading-3"
                    className={cc([
                      [styles.seasonItem],
                      {
                        [styles.seasonBf]: urlBF === 'black_friday',
                      },
                    ])}
                  >
                    Города отправления →
                  </Anchor>
                  {/* <div className={styles.arrowRight}>→</div> */}
                </div>
              )}

              <ul className={styles.dropDownMenuFeatures}>
                {startCities?.map((startCity, key) => (
                  <li
                    key={key}
                    className={styles.dropDownMenuFeature}
                    itemProp="name"
                  >
                    <Anchor
                      href={`/popdir/${startCity.href}${
                        isSea ? '' : '/result'
                      }`}
                      rel="noreferrer"
                      onClick={() => {
                        YaAndGaHeader('click_any_city_in_mega_menu');
                      }}
                      itemProp="url"
                      className={urlBF === 'black_friday' && styles.colorText}
                    >
                      {startCity.h1}
                    </Anchor>
                  </li>
                ))}
                {!isSea && (
                  <Anchor
                    href={'/expeditionsCruise'}
                    color="primaryColor"
                    variant="heading-3"
                  >
                    <div
                      className={
                        urlBF === 'black_friday'
                          ? styles.linkWrapperBf
                          : styles.linkWrapper
                      }
                    >
                      Экспедиционные круизы →
                      {/* <Icon
                        leftGap={10}
                        icon={
                          <IconSVG
                            name={'common/big-right'}
                            width={16}
                            height={24}
                            title="Все круизы"
                          />
                        }
                        title="Все круизы"
                      /> */}
                    </div>
                  </Anchor>
                )}
              </ul>
            </div>
            <div>
              {isSea ? (
                <Text style={{ paddingLeft: 4 }} variant="heading-3">
                  Популярные направления
                </Text>
              ) : (
                <div className={styles.seasonItemWrapper}>
                  <Anchor
                    href={'/poproutes'}
                    variant="heading-3"
                    className={cc([
                      [styles.seasonItem],
                      {
                        [styles.seasonBf]: urlBF === 'black_friday',
                      },
                    ])}
                  >
                    Популярные направления →
                  </Anchor>
                  {/* <div className={styles.arrowRight}>→</div> */}
                </div>
              )}
              <ul className={styles.dropDownMenuFeatures}>
                {popularDir?.map((popDir, key) => (
                  <li key={key} className={styles.dropDownMenuFeature}>
                    <Anchor
                      href={`/popdir/${popDir.href}${isSea ? '' : '/result'}`}
                      rel="nofollow"
                      onClick={() => {
                        YaAndGaHeader('click_any_departure_in_mega_menu');
                      }}
                      className={urlBF === 'black_friday' && styles.colorText}
                    >
                      {popDir.h1}
                    </Anchor>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              {/* {!isSea && (
              <div>
                <Anchor
                  href={allCruises}
                  target={isMobile ? '' : '_blank'}
                  color="primaryColor"
                  rel="noreferrer"
                  variant="heading-3"

                  onClick={() => {
                    YaAndGaHeader('click_all_cruises_in_mega_menu');
                  }}
                >
                  Все круизы{' '}
                  <Icon leftGap={10} icon={bigRight} title="Все круизы" />
                </Anchor>
                <div>
                  <Text variant="text-1">
                    Укажите свои пожелания чтобы найти
                    <br /> подходящие круизы
                  </Text>
                </div>
              </div>
            )} */}
              <HeaderSeason isSea={isSea} isMobile={isMobile} urlBF={urlBF} />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default HeaderCruises;
