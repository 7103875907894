import React from 'react';
import dayjs from 'dayjs';
import cc from 'classcat';
import styles from '../header.module.sass';
import { Anchor } from '../../../../Text';

export const HeaderSeason = ({ isSea, isMobile, urlBF }) => {
  const month = Number(dayjs().format('M'));

  return (
    <>
      {!isSea && (
        <div>
          <div className={styles.ItemWrapper}>
            <Anchor
              href={'/twentyFourthYear'}
              variant="heading-3"
              className={cc([
                [styles.seasonItem],
                {
                  [styles.seasonBf]: urlBF === 'black_friday',
                },
              ])}
            >
              Круизы на 2024 год
              <span className={styles.arrowRight}>→</span>
            </Anchor>
          </div>
          <div className={styles.season}>
            {month < 6 && (
              <div>
                <div>
                  <Anchor
                    href={'/2024/spring'}
                    variant={'text-4'}
                    className={cc([
                      [styles.seasonItem],
                      {
                        [styles.seasonBf]: urlBF === 'black_friday',
                      },
                    ])}
                  >
                    Весна
                  </Anchor>
                </div>
                {month < 5 && (
                  <div>
                    <Anchor
                      href={'/2024/spring/april'}
                      variant={'text'}
                      className={cc([
                        [styles.seasonItem],
                        {
                          [styles.seasonBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Апрель
                    </Anchor>
                  </div>
                )}
                {month < 6 && (
                  <div>
                    <Anchor
                      href={'/2024/spring/may'}
                      variant={'text'}
                      className={cc([
                        [styles.seasonItem],
                        {
                          [styles.seasonBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Май
                    </Anchor>
                  </div>
                )}
              </div>
            )}
            {month < 9 && (
              <div>
                <div>
                  <Anchor
                    href={'/2024/summer'}
                    variant={'text-4'}
                    className={cc([
                      [styles.seasonItem],
                      {
                        [styles.seasonBf]: urlBF === 'black_friday',
                      },
                    ])}
                  >
                    Лето
                  </Anchor>
                </div>
                {month < 7 && (
                  <div>
                    <Anchor
                      href={'/2024/summer/june'}
                      variant={'text'}
                      className={cc([
                        [styles.seasonItem],
                        {
                          [styles.seasonBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Июнь
                    </Anchor>
                  </div>
                )}
                {month < 8 && (
                  <div>
                    <Anchor
                      href={'/2024/summer/july'}
                      variant={'text'}
                      className={cc([
                        [styles.seasonItem],
                        {
                          [styles.seasonBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Июль
                    </Anchor>
                  </div>
                )}
                {month < 9 && (
                  <div>
                    <Anchor
                      href={'/2024/summer/august'}
                      variant={'text'}
                      className={cc([
                        [styles.seasonItem],
                        {
                          [styles.seasonBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Август
                    </Anchor>
                  </div>
                )}
              </div>
            )}
            {month < 12 && (
              <div>
                <div>
                  <Anchor
                    href={'/2024/autumn'}
                    variant={'text-4'}
                    className={cc([
                      [styles.seasonItem],
                      {
                        [styles.seasonBf]: urlBF === 'black_friday',
                      },
                    ])}
                  >
                    Осень
                  </Anchor>
                </div>
                {month < 10 && (
                  <div>
                    <Anchor
                      href={'/2024/autumn/september'}
                      variant={'text'}
                      className={cc([
                        [styles.seasonItem],
                        {
                          [styles.seasonBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Сентябрь
                    </Anchor>
                  </div>
                )}
                {month < 11 && (
                  <div>
                    <Anchor
                      href={'/2024/autumn/october'}
                      variant={'text'}
                      className={cc([
                        [styles.seasonItem],
                        {
                          [styles.seasonBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Октябрь
                    </Anchor>
                  </div>
                )}
                {month < 12 && (
                  <div>
                    <Anchor
                      href={'/2024/autumn/november'}
                      variant={'text'}
                      className={cc([
                        [styles.seasonItem],
                        {
                          [styles.seasonBf]: urlBF === 'black_friday',
                        },
                      ])}
                    >
                      Ноябрь
                    </Anchor>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {!isSea && (
        <div style={{ marginTop: '16px' }}>
          <div className={styles.ItemWrapper}>
            <Anchor
              href={'/2025'}
              variant="heading-3"
              className={cc([
                [styles.seasonItem],
                {
                  [styles.seasonBf]: urlBF === 'black_friday',
                },
              ])}
            >
              Круизы на 2025 год
              <span className={styles.arrowRight}>→</span>
            </Anchor>
          </div>

          <div className={styles.season}>
            <div>
              <div>
                <Anchor
                  href={'/2025/spring'}
                  variant={'text-4'}
                  className={cc([
                    [styles.seasonItem],
                    {
                      [styles.seasonBf]: urlBF === 'black_friday',
                    },
                  ])}
                >
                  Весна
                </Anchor>
              </div>
              <div>
                <Anchor
                  href={'/2025/spring/april'}
                  variant={'text'}
                  className={cc([
                    [styles.seasonItem],
                    {
                      [styles.seasonBf]: urlBF === 'black_friday',
                    },
                  ])}
                >
                  Апрель
                </Anchor>
              </div>
              <div>
                <Anchor
                  href={'/2025/spring/may'}
                  variant={'text'}
                  className={cc([
                    [styles.seasonItem],
                    {
                      [styles.seasonBf]: urlBF === 'black_friday',
                    },
                  ])}
                >
                  Май
                </Anchor>
              </div>
            </div>
            <div>
              <div>
                <Anchor
                  href={'/2025/summer'}
                  variant={'text-4'}
                  className={cc([
                    [styles.seasonItem],
                    {
                      [styles.seasonBf]: urlBF === 'black_friday',
                    },
                  ])}
                >
                  Лето
                </Anchor>
              </div>
              <div>
                <Anchor
                  href={'/2025/summer/june'}
                  variant={'text'}
                  className={cc([
                    [styles.seasonItem],
                    {
                      [styles.seasonBf]: urlBF === 'black_friday',
                    },
                  ])}
                >
                  Июнь
                </Anchor>
              </div>
              <div>
                <Anchor
                  href={'/2025/summer/july'}
                  variant={'text'}
                  className={cc([
                    [styles.seasonItem],
                    {
                      [styles.seasonBf]: urlBF === 'black_friday',
                    },
                  ])}
                >
                  Июль
                </Anchor>
              </div>
              <div>
                <Anchor
                  href={'/2025/summer/august'}
                  variant={'text'}
                  className={cc([
                    [styles.seasonItem],
                    {
                      [styles.seasonBf]: urlBF === 'black_friday',
                    },
                  ])}
                >
                  Август
                </Anchor>
              </div>
            </div>
            <div>
              <div>
                <Anchor
                  href={'/2025/autumn'}
                  variant={'text-4'}
                  className={cc([
                    [styles.seasonItem],
                    {
                      [styles.seasonBf]: urlBF === 'black_friday',
                    },
                  ])}
                >
                  Осень
                </Anchor>
              </div>
              <div>
                <Anchor
                  href={'/2025/autumn/september'}
                  variant={'text'}
                  className={cc([
                    [styles.seasonItem],
                    {
                      [styles.seasonBf]: urlBF === 'black_friday',
                    },
                  ])}
                >
                  Сентябрь
                </Anchor>
              </div>
              <div>
                <Anchor
                  href={'/2025/autumn/october'}
                  variant={'text'}
                  className={cc([
                    [styles.seasonItem],
                    {
                      [styles.seasonBf]: urlBF === 'black_friday',
                    },
                  ])}
                >
                  Октябрь
                </Anchor>
              </div>
              <div>
                <Anchor
                  href={'/2025/autumn/november'}
                  variant={'text'}
                  className={cc([
                    [styles.seasonItem],
                    {
                      [styles.seasonBf]: urlBF === 'black_friday',
                    },
                  ])}
                >
                  Ноябрь
                </Anchor>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
